.wrapper {
  @apply fixed z-20 w-full bg-white shadow-sm top-14 sm:top-20 lg:top-30 py-7;
  @apply box-content;

  &:global(.has-admin-bar) {
    @apply top-25 sm:top-32 md:top-29 lg:top-39;
  }

  & :global(.inner-wrapper) {
    @apply flex mx-auto max-w-8xl px-5 md:px-8 lg:px-0;
  }
}
